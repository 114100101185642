import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import vuemeta from 'vue-meta';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './plugins';
import { guest } from './plugins/axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading-overlay', Loading);

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    guest,
    vuemeta,
    render: h => h(App)
}).$mount('#app');

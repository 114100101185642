// Imports
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            component: () => import('@/layouts/home/Index.vue'),

            children: [
                {
                    path: '',
                    name: 'Home',
                    component: () => import('@/views/home/Index.vue')
                },

                {
                    path: 'services',
                    name: 'Services',
                    component: () => import('@/views/product/Index.vue'),
                    meta: { src: require('@/assets/pro.jpg') }
                },
                {
                    path: 'products',
                    name: 'Products',
                    component: () => import('@/views/gallery/Index.vue'),
                    meta: { src: require('@/assets/pro.jpg') }
                },
                {
                    path: 'contact',
                    name: 'Contact',
                    component: () => import('@/views/contact-us/Index.vue'),
                    meta: { src: require('@/assets/pro.jpg') }
                },

                {
                    path: '*',
                    name: 'FourOhFour',
                    component: () => import('@/views/404/Index.vue')
                }
            ]
        }
    ]
});

/*
router.beforeEach((to, from, next) => {
    const Authenticate = ['/user/dashboard', '/verification', '/user/invest'];
    const authRequired = Authenticate.includes(to.path);
    const token = localStorage.getItem('token');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !token) {
        next('/signin');
    } else {
        next();
    }
});
*/

export default router;

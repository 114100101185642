<template>
    <v-expand-x-transition mode="out-in">
        <router-view />
    </v-expand-x-transition>
</template>

<script>
export default {
    name: 'App',
    metaInfo: {
        title: 'sylvaleoglobal - The home of chemicals of all kinds  ',
        titleTemplate: '%s | Sylvaleoglobal',
        htmlAttrs: { lang: 'en' },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
            {
                name: 'description',
                content:
                    'food chemicals, Solvents chemicals, Industrial chemicals, our products includes Dextrose monohydrate, citric acid, Monosodium glutamate, sodium benzoate, calcium hypochlorite, Hydrated lime, Allumium sulphate, Ethyl alcohol (Ethanol), Food flavours, caustic soda, Industrial chemicals, Calcium hypochlorite (Chlorine), Hydrated Lime, Alluminium sulphate, Ethyl alcohol,(Ethanol),Quicklime , Soda ash, Boric acid , Borax acid, Hydrochloric acid, Phosphoric acid, Acetic acid, Sulphuric acid , Sulphonic acid, Hydrochloric acid, Hydrogen peroxide, Sodium hypochlorite, Glycerin, Formaldehyde, Sodium benzoate, Citric acid anhydrous, Citric acid monohydrate, Potassium sorbate, Dextrose monohydrate, Maltodextrin , Sodium bicarbonate, Sorbic acid, Calcium propionate, Sodium citrate, Lactic acid, Malic acid, Aspartame, Acesulfame-k, Xanthan gum, Guar gum, Maize/corn starch, Glycerin monostearate, Monosodium glutamate',
            },
            {
                name: 'description',
                content: 'Sylvaleo Global Limited',
            },
            {
                property: 'og:title',
                content:
                    'Sylvaleo Global - Home of Solvents Industrial and Food Chemicals',
            },
            { property: 'og:site_name', content: 'Sylvaleo Global' },
            { property: 'og:type', content: 'website' },
            { name: 'robots', content: 'index,follow' },
        ],
    },
};
</script>

<style>
.extra-padding {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
}

@media screen and (max-width: 959px) {
    .extra-padding {
        padding-top: 48px !important;
        padding-bottom: 48px !important;
    }
}
</style>

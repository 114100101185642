<template>
    <v-theme-provider :dark="dark">
        <div>
            <base-img
                :src="require('@/assets/swiftpro-logo-black.jpeg')"
                contain
                max-width="200"
                min-height="78"
                width="100%"
            />

            <base-title
                size="body-1"
                space="4"
                title="SYLVELEO GLOBAL LIMITED"
                weight="regular"
            />

            <base-body
                >Sylvaleoglobal Limited is a company that deals with all kinds
                of food chemicals, Solvents chemicals, Industrial chemicals, our
                products includes Dextrose monohydrate, citric acid, Monosodium
                glutamate, sodium benzoate, calcium hypochlorite, Hydrated lime,
                Allumium sulphate, Ethyl alcohol (Ethanol), Food flavours,
                caustic soda.</base-body
            >

            <base-btn class="mb-12" color="white" outlined>More Info</base-btn>
        </div>
    </v-theme-provider>
</template>

<script>
export default {
    name: 'BaseInfo',

    props: {
        title: String,
        dark: Boolean,
    },

    data: () => ({
        business: [
            {
                icon: 'mdi-map-marker-outline',
                title: 'Address',
                text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
            },
            {
                icon: 'mdi-cellphone',
                title: 'Phone',
                text: '01 (800) 433 744<br>01 (800) 433 633',
            },
            {
                icon: 'mdi-email',
                title: 'Email',
                text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
            },
        ],
    }),
};
</script>

